.invoice-box {
    margin: 10px auto 30px;
    border: 1px solid #d3d3d3;
    width: 302px;
    /* max-width: 400px; */
    display: block;
}

.invoice-box1 {
    margin: 10px auto 30px;
    border: 1px solid #d3d3d3;
    width: 100%;
    max-width: 600px;
    display: block;
}
.invoice-inside {
    background: #ffffff;
    padding: 20px;
    z-index: 20;
    box-shadow: 0 0 1px 0 #ccc;
    -webkit-box-shadow: 0 0 1px 0 #ccc;
    -moz-box-shadow: 0 0 1px 0 #ccc;
    border-radius: 10px;
}

.invoicetable tbody tr td{
    font-size: 12px;
}

.padbot{
    padding-top: 0 !important;
     padding-bottom: 0 !important
}
